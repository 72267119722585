/* src/App.css */

body {
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin: 0;
  background: #f7f2e7;
}

.container {
  text-align: center;
  color: #333;
}

.title {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
}

.subtitle {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

/* Máquina tragamonedas con fondo personalizado */
.slot-machine {
  position: relative;
  display: inline-block;
  margin-bottom: 2rem;
  padding: 20px;
  background: #222;
  border-radius: 20px;
  border: 3px solid #333;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
}

/* Fondo de los íconos */
.reels {
  display: flex;
  gap: 10px;
  padding: 10px;
  perspective: 1000px;
}

.reel {
  width: 80px; /* Aumenta ligeramente para acomodar el marco */
  height: 100px;
  position: relative;
  overflow: hidden;
  background: linear-gradient(145deg, #555, #333); /* Fondo metálico oscuro */
  border-radius: 10px;
  box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.6);
}

/* Cilindro y animación */
.reel-cylinder {
  position: absolute;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
}

.reel.spinning .reel-cylinder {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotateX(0deg); }
  100% { transform: rotateX(-360deg); }
}

/* Compartimento para cada ítem del reel */
.reel-item {
  position: absolute;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: #333;
  background: #fff; /* Fondo del compartimento */
  border: 3px solid #ccc; /* Borde del compartimento */
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.reel-item:nth-child(1) { transform: rotateX(0deg) translateZ(30px); }
.reel-item:nth-child(2) { transform: rotateX(120deg) translateZ(30px); }
.reel-item:nth-child(3) { transform: rotateX(240deg) translateZ(30px); }

/* Palanca con pomo y barra */
.lever {
  position: absolute;
  top: -20px;
  right: -60px;
  cursor: pointer;
}

.lever-bar {
  position: relative;
  width: 10px;
  height: 100px;
  background-color: #888;
  border-radius: 5px;
  transition: transform 0.3s ease;
}

.lever-knob {
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 30px;
  height: 30px;
  background-color: #d9534f;
  border: 3px solid #900;
  border-radius: 50%;
}

.pulled {
  transform: rotate(30deg) translateY(20px);
}

/* Caja de resultados */
.result-box {
  margin-top: 20px;
  padding: 20px;
  background-color: #f0f0f0;
  border: 2px solid #ccc;
  border-radius: 15px;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.result-text {
  font-size: 1.1rem;
  margin-bottom: 15px;
}

.submit-button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #ff5722;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #e64a19;
}